 
  .btn-flotante {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    z-index: 999;
    width: var(--btn-size);
    height: var(--btn-size);
    border-radius: 50%;
    color: #fff;
    font-size: 30px;
    text-decoration: none;
    transition: box-shadow 0.3s;
  }
  
  .btn-flotante:hover {
    box-shadow: var(--btn-shadow-hover);
  }
  
  .btn-flotante a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: #fff;
  }
  
  .btn-flotante .bi {
    font-size: 30px;
  }
  
  /* Específicos para cada botón */
  .btn-whatsapp {
    bottom: 20px;
    background-color: var(--btn-color-whatsapp);
  }
  
  .btn-call {
    bottom: 100px; /* Ajusta esta distancia según sea necesario para que no se superponga */
    background-color: var(--btn-color-call);
  }
  