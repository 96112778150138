.dark-button {
    border: none;
    background-color: transparent;
    color: inherit;
    padding: 0;
  }
  
  .dark-button:focus {
    box-shadow: none;
  }
  
  .dark-button:hover {
    background-color: transparent;
  }
  