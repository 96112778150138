

/* .section-comparativa {
    background: rgb(174,235,238);
  background: linear-gradient(186deg, rgba(27,91,149,1) 0%, rgba(255,255,255,0.908000700280112) 33%, rgba(255,255,255,0.908000700280112) 66%, rgba(27,91,149,1) 100%);
} */
.comparativa .container{
    padding: 0 0;
    margin: 0 auto 0 ;
}
.colour-block {
    background:#823A9C;
    width:60%;
    padding:7% 20% 10% 20%;
    color:#fff;
  }
.section-comparativa h2{
    color: var(--color-primary);
}
.SectionInfo > p {
    font-size: 1.2rem;
    color: var(--color-primary);
}
  
  .tableInfo {

    margin: max(1rem, 3rem);
    border: 0.35rem solid;
    padding: 3rem;
    border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    ) 30;
    animation: borderRotate 3s linear infinite forwards;
  }
.tableInfo-red {
    padding: 20px;
    border: 5px solid var(--color-red);
    border-radius: 10px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
}
.tableInfo h5{
    color: var(--color-primary);
}
.tableInfo p{
    font-weight: 600;
}
.tableInfo ul{
    margin: 0;
    padding: 0;
}
.tableInfo li {
    list-style-type: none;
    font-size: 1.2rem;
    font-weight: 600;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}