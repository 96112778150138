
  
  .info h2 {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 30px;
    text-shadow: 0px 0px 3px white;
    background: transparent;
  }
  .info h2 span {
    font-weight: 700;
    font-size: 3.5rem;
  }
  .info p {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 30px;
  }
  .info ul > li {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .info .call-us {
    left: 10%;
    right: 10%;
    bottom: 10%;
    background-color: #fff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    padding: 20px;
    text-align: center;
  }
  
  .info .call-us h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: var(--font-default);
  }
  
  .info .call-us p {
    font-size: 28px;
    font-weight: 700;
    color: var(--color-primary);
  }
  
  .info .content ul {
    list-style: none;
    padding: 0;
  }
  
  .info .content ul li {
    padding: 0 0 8px 26px;
    position: relative;
  }
  
  .info .content ul i {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -3px;
    color: var(--color-primary);
  }
  
  .info .content p:last-child {
    margin-bottom: 0;
  }
  
  .info .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(var(--color-primary) 50%, rgba(18, 153, 206, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .info .play-btn:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(18, 162, 206, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .info .play-btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .info .play-btn:hover:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  .info .play-btn:hover:after {
    border-left: 15px solid var(--color-primary);
    transform: scale(20);
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }