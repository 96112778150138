/*--------------------------------------------------------------
# Proceso Section
--------------------------------------------------------------*/
.section-proceso {
  background-color: var(--bs-light);
  height: 100%;
  width: 100%;
  min-height: 80vh;
  background: rgb(44,62,80);
  background-image: linear-gradient(60deg, #153458 0%, #485563 100%);
  color: #fff;
}

.text-proceso {
  font-size: 3.5em;
  -webkit-text-stroke: 2px #25245d;
  paint-order: stroke fill;
  color: white;
  margin-bottom: 30px;
}

    /*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/

  @media (max-width: 575px) {
    .menu .nav-link {
      margin: 0 10px;
    }
  }
  
  .nav-tabs .nav-link h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    font-family: var(--font-secondary);
  }
  
  @media (max-width: 575px) {
    .menu .nav-link h4 {
      font-size: 16px;
    }
  }

  .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--mariner-600);
    border-radius: 5px;
    border-bottom: 4px solid var(--color-primary);
  }
  .nav-tabs .nav-link:hover {
    transition: background-color 1s ease;
    color: #fff;
    background-color: var(--mariner-400);
    border-radius: 5px;
    border-bottom: 4px solid var(--color-primary);
  }
  .proceso .tab-content{
    font-size: 1.5rem;
  }

  
    