:root {
  --dark-opacity: #000000;
}
.doctor {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../images/index/fondo-medico.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.doctor ul > li {
  font-weight: 500;
  font-size: 1.1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  list-style: none;
}
.doctor .btn-doctor {
    background: var(--color-primary);
    border: 0;
    padding: 12px 40px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    text-decoration: none;
  }
  
  .doctor .btn-doctor:hover {
    background: #0d1e81;
    padding: 12px;
  }
  .doctor a {
    text-decoration: none;
    color: #0d1e81;
  }