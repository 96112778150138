.boton-regreso {
    background: var(--color-primary);
    border: 0;
    padding: 12px 40px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    text-decoration: none;
  }
  
  .boton-regreso:hover {
    background: #0d1e81;
    padding: 12px;
  }