

/* Estilos politica de Cookies */

.Section-Aviso-Priv {
    max-width: 1000px;
    margin: 100px auto;
    padding: 50px;
    background-color: var(--bs-light-bg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: start;
  }
  
.Section-Aviso-Priv  h1 {
    text-align: center;
    font-size: 3em;
    margin-top: 0
    }

.Section-Aviso-Priv h2 {
font-size: 2em;
margin-top: 40px;
}

.Section-Aviso-Priv p {
margin-top: 20px;
line-height: 1.5;
}

