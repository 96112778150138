.custom-modal .modal-dialog {
    width: 60%;
  }
  
  .custom-iframe {
    width: 100%;
  }
  
  @media (max-width: 992px) { /* Tamaño de pantalla de tablet */
    .custom-modal .modal-dialog {
      width: 50%;
    }
  }
  
  @media (max-width: 576px) { /* Tamaño de pantalla de celular */
    .custom-modal .modal-dialog {
      width: 100%;
    }
  }
  