/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
    background: #5bd1ff00;
    padding: 30px;
    height: 100%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.219);
  }
  
  .contact .info-item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    font-size: 24px;
    line-height: 0;
    color: #fff;
    background: var(--color-primary);
    border-radius: 50%;
    margin-right: 15px;
  }
  .contact .info-item .icon-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 24px;
    line-height: 0;
    color: #fff;
    border-radius: 50%;
    margin-right: 15px;
    padding: 1.2rem;
  }
  .icon-blue{
    background: var(--color-primary);
  }
  .icon-green{
    background: #1EBEA5;
  }
  
  .contact .info-item h3 {
    font-size: 20px;
    color: var(--color-primary);
    font-weight: 700;
    margin: 0 0 5px 0;
  }
  
  .contact .info-item p {
    padding: 0;
    margin: 0;
    line-height: 24px;
    font-size: 14px;
  }
  
  .contact .info-item .social-links a {
    font-size: 24px;
    display: inline-block;
    color: rgba(55, 55, 63, 0.7);
    line-height: 1;
    margin: 4px 6px 0 0;
    transition: 0.3s;
  }
  
  .contact .info-item .social-links a:hover {
    color: var(--color-primary);
  }
  
  .contact .php-email-form {
    width: 100%;
    margin-top: 30px;
    background: var(--bs-light);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.432);
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 20px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #df1529;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #059652;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #059652;
    border-top-color: #fff;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    box-shadow: 0 0 15px rgba(13, 55, 196, 0.432);
    font-weight: 600;
  }
  
  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--color-primary);
  }
  
  .contact .php-email-form input {
    height: 48px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: var(--color-primary);
    border: 0;
    padding: 12px 40px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #0d1e81;
    padding: 12px;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  /*--------------------------------------------------------------
# info Section
--------------------------------------------------------------*/
.section-proceso {
  background-color: var(--bs-light);
  height: 100%;
  width: 100%;
  min-height: 80vh;
}
  .contact .contact-img {
      min-height: 500px;
    }
    
    .contact h2 {
      font-weight: 700;
      font-size: 2.5rem;
      margin-bottom: 30px;
      text-shadow: 0px 0px 3px white;
      background: transparent;
    }
    .contact h2 span {
      font-weight: 700;
      font-size: 3rem;
    }
    .contact p {
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 30px;
    }
    .contact ul > li {
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 10px;
    }
    
    .contact .call-us {
      left: 10%;
      right: 10%;
      bottom: 10%;
      background-color: #fff;
      box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
      padding: 20px;
      text-align: center;
    }
    
    .contact .call-us h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 5px;
      font-family: var(--font-default);
    }
    
    .contact .call-us p {
      font-size: 28px;
      font-weight: 700;
      color: var(--color-primary);
    }
    
    .contact .content ul {
      list-style: none;
      padding: 0;
    }
    
    .contact .content ul li {
      padding: 0 0 8px 26px;
      position: relative;
    }
    
    .contact .content ul i {
      position: absolute;
      font-size: 20px;
      left: 0;
      top: -3px;
      color: var(--color-primary);
    }
    
    .contact .content p:last-child {
      margin-bottom: 0;
    }
    
    .contact .play-btn {
      width: 94px;
      height: 94px;
      background: radial-gradient(var(--color-primary) 50%, rgba(18, 153, 206, 0.4) 52%);
      border-radius: 50%;
      display: block;
      position: absolute;
      left: calc(50% - 47px);
      top: calc(50% - 47px);
      overflow: hidden;
    }
    
    .contact .play-btn:before {
      content: "";
      position: absolute;
      width: 120px;
      height: 120px;
      animation-delay: 0s;
      animation: pulsate-btn 2s;
      animation-direction: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: steps;
      opacity: 1;
      border-radius: 50%;
      border: 5px solid rgba(18, 162, 206, 0.7);
      top: -15%;
      left: -15%;
      background: rgba(198, 16, 0, 0);
    }
    
    .contact .play-btn:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-40%) translateY(-50%);
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 15px solid #fff;
      z-index: 100;
      transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    
    .contact .play-btn:hover:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-40%) translateY(-50%);
      width: 0;
      height: 0;
      border: none;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 15px solid #fff;
      z-index: 200;
      animation: none;
      border-radius: 0;
    }
    
    .contact .play-btn:hover:after {
      border-left: 15px solid var(--color-primary);
      transform: scale(20);
    }
    
    @keyframes pulsate-btn {
      0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
      }
    
      100% {
        transform: scale(1, 1);
        opacity: 0;
      }
    }


    /*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/
.menu .nav-tabs-contact {
    border: 0;
  }
  
  .nav-tabs-contact .nav-item {
    transition: 0.3s;
    color: var(--color-secondary);
    margin: 0 0;
  }
  .nav-tabs-contact .nav-link {
    transition: 0.3s;
    color: var(--color-secondary);
    border-radius: 0;
    cursor: pointer;
    height: 100%;
    border: 0;
    border-bottom: 2px solid #d400ff;
  }
  
  @media (max-width: 575px) {
    .menu .nav-link {
      margin: 0 10px;
    }
  }
  
  .nav-tabs-contact .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .nav-tabs-contact .nav-link h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    font-family: var(--font-secondary);
  }
  
  @media (max-width: 575px) {
    .menu .nav-link h4 {
      font-size: 16px;
    }
  }
  
  .nav-tabs-contact .nav-link:hover {
    color: var(--color-primary);
  }
  
  .nav-tabs-contact .nav-link.active {
    color: var(--color-primary);
    border-bottom: 4px solid var(--color-primary);
  }
  
    