
.logo {
    width: 180px;
    height: 100%;
    max-height: 60px;
    -o-object-fit: cover;
    object-fit: cover;
    transition: background-color 1s ease;
    border-radius: 30px;
}

.scrolled {
  background-color: rgba(0, 0, 0, 0.747);
  border-radius: 3rem;
  margin-left: 30px;
  margin-right: 30px;
  top: 10px;
  transition: background-color 2s ease;
  }
.scrolled .logo{
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  }
  /* Estilos para laptop y desktop */
@media (min-width: 992px) {
  .scrolled {
      margin-top: 20px;
      margin-left: 10rem; /* Aumenta el margen izquierdo del navbar */
      margin-right: 10rem; /* Aumenta el margen derecho del navbar */
  }
  .dark-button {
    color: var(--bs-body-color);
    margin-right: 20px;
  }
}

  .scrolled .nav-link {
    color: rgb(255, 255, 255);
  }

  .scrolled .navbar-toggler {
    border: 1px solid white;
  }
  .scrolled .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(35, 113, 196, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
  .scrolled .dark-button {
    color: var(--bs-body-color);
  }

  
  