/* section.pricing {
    background: #007bff;
    background: linear-gradient(to right, #1e59a5, #ffffff50);
  } */
  .pricing {
    background-color: var(--color-bg);
    padding: 10px 0 200px 0;
  }
  
  .pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .pricing h2 {
    color: #fff;
  }
  
  .pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
  }
  
  .pricing .card-price {
    font-size: 3rem;
    margin: 0;
  }
  
  .pricing .card-price .period {
    font-size: 0.8rem;
  }
  
  .pricing ul li {
    margin-bottom: 1rem;
  }
  
  .pricing .text-muted {
    opacity: 0.7;
  }
  
  .pricing button {
    background: var(--color-primary);
    border: 0;
    padding: 12px 40px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    text-decoration: none;
  }
  .pricing button:hover {
    background: #fff;
    border: solid var(--color-primary);
    color: var(--color-primary);
    font-weight: 600;
  }

  @media (min-width: 992px) {
    .pricing .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
  
    .pricing .card:hover button {
      opacity: 1;
    }
  }

  .pricing ul {
    list-style: none;
    font-weight: 500;
  }


  .image-precios {
    width: 5rem;
    margin-bottom: 1rem;
  }