/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
section {
  overflow: hidden;
  padding: 80px 0;
  scroll-margin-top: 90px;
  overflow: clip;
}

.hero {
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding: 20px 0 60px 0;
  background-image: url('../../images/index/background-mobile.webp');
  background-size: cover;
  z-index: 1;
}
@media (min-width: 640px) {
  .hero {
    background-image: url('../../images/index/background.webp');
  }
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0.7;
  z-index: 2;
}
.container {
  z-index: 3;
}

  
  .hero h1 {
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--bs-white);
    font-family: var(--font-primary);
  }
  
  .hero h1 span {
    color: var(--bs-white);
  }
  
  .hero p {
    color: var(--bs-white);
    font-weight: 400;
    margin-bottom: 30px;
    font-size: 1.4rem;
  }
  
  .hero .btn-book-a-table {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 36px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: var(--bs-primary);
    box-shadow: 0 8px 28px rgba(var(--bs-primary-rgb), 0.2);
  }
  
  .hero .btn-book-a-table:hover {
    background: var(--bs-secondary);
    box-shadow: 0 8px 28px rgba(var(--bs-secondary-rgb), 0.2);
  }
  
  .hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: var(--bs-secondary);
    font-weight: 600;
  }
  
  .hero .btn-watch-video i {
    color: var(--bs-primary);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  .hero .btn-watch-video:hover {
    color: var(--bs-primary);
  }
  
  .hero .btn-watch-video:hover i {
    color: rgba(206, 18, 18, 0.8);
  }
  
  @media (max-width: 640px) {
    .hero h2 {
      font-size: 36px;
    }
  
    .hero .btn-get-started,
    .hero .btn-watch-video {
      font-size: 14px;
    }
  }